import { AuthProvider } from "react-admin";
import {jwtDecode} from "jwt-decode";
import User from "./model/User";

const authProvider = ({
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    user,
  }: any): AuthProvider => ({
    login: () => {
        return Promise.resolve();
    },
    logout: () => {
        return logout();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => (isAuthenticated ? Promise.resolve() : loginWithRedirect()),
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: async() => {
      let permissions = (jwtDecode(await getAccessTokenSilently()) as any).permissions;
      return Promise.resolve(new User(
        user.id,
        user.name, 
        user.picture,
        permissions
      ));
    }
  });
  
  export default authProvider;