import React, { FC } from 'react';
import logo from '../../img/logo.png';
import { Box, Link, Typography } from '@mui/material';


const Footer : FC<any> = () => {
    return <Box style={{
        width: '100%',
        height: 100,
        backgroundColor: '#E4E9F0'
      }} display="flex" alignItems="center" justifyContent="flex-end">
        <Box flexDirection="row" display="flex">
          <Box marginTop={1}><img src={logo} width="250"/></Box>
          <Box display="flex" flexDirection="column" marginLeft={5} marginRight={5} alignItems="center" justifyContent="flex-start">
            <Box><Typography variant="caption">© 2021 Lauwers Fiscale Advocaten</Typography></Box>
            <Box><Typography variant="caption">All rights reserved</Typography></Box>
            <Box><Link href="mailto:support@ml2grow.com">Technical support</Link></Box>
            </Box>
          </Box>
      </Box>
}

export default Footer;
