import React, { createContext, FC, useCallback, useEffect } from 'react';
import './App.css';
import { Admin, Resource, ListGuesser, required, useEditController, List, FileInput, FileField, DateFieldProps, ListViewProps, FunctionField, SingleFieldList, ChipField, ArrayField, AutocompleteArrayInput, ReferenceArrayInput, ArrayInput, useNotify, Button } from 'react-admin';
import authProvider from './authProvider';
import { useAuth0 } from '@auth0/auth0-react';
import documentsDataProvider from './data/documentsDataProvider';
import { DocumentCreate } from './components/document/DocumentCreate';
import { DocumentsList } from './components/document/DocumentsList';
import { DocumentEdit } from './components/document/DocumentEdit';
import { DocumentShow } from './components/document/DocumentShow';

import MyLayout from './components/layout/MyLayout';
import { theme } from './themes/theme';
import logo from './img/logo.png';
import Footer from './components/layout/Footer';
import { UsersList } from './components/user/UsersList';
import { UserCreate } from './components/user/UserCreate';
import { OrganisationsList } from './components/organisation/OrganisationsList';
import useStateRef from 'react-usestateref';
import { RiskAssesment } from './components/RiskCalculator/RiskAssesment';
import { Route } from 'react-router-dom';
import customRoutes from './customRoutes';
import { useUser } from './model/User';
import { LegalChat } from './components/legalChat/LegalChat';
import { AssessmentOutlined, ChatBubbleOutline, FaceOutlined, Drafts } from '@mui/icons-material';

export interface UploadStatus {
  src: string,
  status: string,
  url: string
}
export const UploadStatusContext = createContext<{ uploadStatuses: { [key: string]: UploadStatus }, setUploadStatuses: (uploadStatuses: { [key: string]: UploadStatus }) => void }>({
  uploadStatuses: {},
  setUploadStatuses: (uploadStatuses: { [key: string]: UploadStatus }) => { }
})

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();

  const [uploadStatuses, setUploadStatuses, uploadStatusesRef] = useStateRef<{ [key: string]: UploadStatus }>({});

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {

    const customAuthProvider: any = authProvider({
      getAccessTokenSilently: getAccessTokenSilently,
      isAuthenticated: isAuthenticated,
      isLoading: isLoading,
      loginWithRedirect: loginWithRedirect,
      logout: logout,
      user: user
    })

    return (
      <>
        <UploadStatusContext.Provider value={{ uploadStatuses, setUploadStatuses }}>
          <div style={{ flexDirection: 'column', height: '90vh' }}>
            <Admin
              theme={theme}
              layout={MyLayout as any}
              dataProvider={documentsDataProvider(getAccessTokenSilently, (status: UploadStatus) => {
                let uploadStatuses = { ...uploadStatusesRef.current };
                if (uploadStatuses[status.src]) {
                  uploadStatuses[status.src].status = status.status;
                  uploadStatuses[status.src].url = status.url;
                } else {
                  uploadStatuses[status.src] = status;
                }
                setUploadStatuses(uploadStatuses);
              })}
              authProvider={customAuthProvider}
            >
              <Resource icon={Drafts} name="documents" list={DocumentsList} edit={DocumentEdit} create={DocumentCreate} show={DocumentShow} />
              <Resource icon={AssessmentOutlined} name="risk assessment" list={RiskAssesment} />
              <Resource icon={ChatBubbleOutline} name="legal assistant" list={LegalChat} />
              <Resource icon={FaceOutlined} name="users" list={UsersList} create={UserCreate} />
              <Resource icon={Drafts} name="organisations" />
              <Resource icon={Drafts} name="roles" />
              <Resource icon={Drafts} name="tags" />

            </Admin>
            <Footer />
          </div>
        </UploadStatusContext.Provider>
      </>
    );
  } else {
    return <button onClick={() => loginWithRedirect}>Log in</button>;
  }
  // return (
  //   <Admin dataProvider={dataProvider}> 
  //     <Resource name="posts" list={ListGuesser} />
  //     <Resource name="comments" list={ListGuesser} />

  //   </Admin>
  // );
}

export default App;
