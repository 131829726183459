import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, useResourceDefinitions } from 'react-admin';
import { useUser } from '../../model/User';
import { useMediaQuery } from '@mui/material';

const Menu = ({ onMenuClick, logout }: any) => {
    const {user, loading} = useUser();
    const isXSmall = useMediaQuery((theme : any) => theme.breakpoints.down('xs'));
    //const open = useSelector((state : any) => state.admin.ui.sidebarOpen);
    const resourcesDefinitions = useResourceDefinitions();
    const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
    if (!user) {
        return <div></div>
    }
    return (
        <div style={{marginTop: 30}}>
            {resources.map(resource => {
                if (resource.hasList && user.canView(resource.name)) {
                    return (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={resource.options && resource.options.label || resource.name}
                            leftIcon={createElement(resource.icon)}
                            onClick={onMenuClick} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                        />
                    );
                }
            })}
        </div>
    );
}

export default Menu