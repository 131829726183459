import get from 'lodash/get';
import { useRecordContext, useRedirect } from 'ra-core';

import { PublicFieldProps, InjectedFieldProps, fieldPropTypes } from './types';
import { UploadStatus } from '../../App';
import { Chip, Typography } from '@mui/material';

const sanitizeFieldRestProps: (props: any) => any = ({
    addLabel,
    allowEmpty,
    basePath,
    cellClassName,
    className,
    emptyText,
    formClassName,
    fullWidth,
    headerClassName,
    label,
    linkType,
    link,
    locale,
    record,
    refetch,
    resource,
    sortable,
    sortBy,
    sortByOrder,
    source,
    textAlign,
    translateChoice,
    ...props
}) => props;


/**
 * Render a link to a file based on a path contained in a record field
 *
 * @example
 * import { FileField } from 'react-admin';
 *
 * <FileField source="url" title="title" />
 *
 * // renders the record { id: 123, url: 'doc.pdf', title: 'Presentation' } as
 * <div>
 *     <a href="doc.pdf" title="Presentation">Presentation</a>
 * </div>
 */
const CustomFileField = (props: FileFieldProps) => {
    const {
        className,
        classes: classesOverride,
        emptyText,
        source,
        title,
        src,
        target,
        download,
        ping,
        rel,
        uploadStatuses,
        ...rest
    } = props;
    const record = useRecordContext(props);
    const sourceValue = get(record, source as any);
    //const classes = useStyles(props);

    let status = 'READY FOR UPLOAD';
    let url = '';
    if (uploadStatuses[record.src]) {
        status = uploadStatuses[record.src].status;
        url = uploadStatuses[record.src].url;
    }

    if (!sourceValue) {
        return emptyText ? (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : (
            <div
                className={className}
                {...sanitizeFieldRestProps(rest)}
            />
        );
    }

    if (Array.isArray(sourceValue)) {
        return (
            <ul
            className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {sourceValue.map((file, index) => {
                    const fileTitleValue = get(file, title as any) || title;
                    const srcValue = get(file, src as any) || title;

                    return (
                        <li key={index}>
                            <a
                                href={srcValue}
                                title={fileTitleValue}
                                target={target}
                                download={download}
                                ping={ping}
                                rel={rel}
                            >
                                {fileTitleValue}
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }

    const titleValue = get(record, title as any) || title;

    return (
        <div
        className={className}
            {...sanitizeFieldRestProps(rest)}
        >
            <a
                href={sourceValue}
                title={titleValue}
                target={target}
                download={download}
                ping={ping}
                rel={rel}
            >
                {titleValue}

            </a>
            <Status status={status} url={url} />
        </div>
    );
};

const Status = ({status, url} : {status: string, url: string}) => {
    const redirect = useRedirect();
    const handleClick= () => url !== '' ? redirect(url): null;
    if (status == 'ERROR') {
        return (
            <Chip label={status} style={{height: '20px', margin: '2px', backgroundColor: 'red', color: 'white'}} onClick={handleClick}/>
        )
    }
    if (status == 'DUPLICATE') {
        return (
            <Chip label={status} style={{height: '20px', margin: '2px', backgroundColor: 'red', color: 'white'}} onClick={handleClick}/>
        )
    }
    if (status == 'UPLOADED') {
        return (
            <Chip label={status} style={{height: '20px', margin: '2px', backgroundColor: 'lightgreen', color: 'darkgreen'}} onClick={handleClick}/>
        )
    }
    return (
        <Chip label={status} style={{height: '20px', margin: '2px'}}/>
    )
}

export interface FileFieldProps extends PublicFieldProps, InjectedFieldProps {
    src?: string;
    status?: string;
    title?: string;
    target?: string;
    download?: boolean | string;
    ping?: string;
    rel?: string;
    classes?: object;
    uploadStatuses: {[key: string] : UploadStatus}
}


export default CustomFileField;