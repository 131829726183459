import moment from "moment";
import React, { FC } from "react";
import { DateFieldProps, FunctionField } from "react-admin";

const UnixTimestampDateField: FC<DateFieldProps> = (props) => {
  return <FunctionField render={(record: any) => {

    return moment(record[props.source!] * 1000).format('MMMM Do YYYY, HH:mm:ss');
  }} />;
}

export const UnixDateField: FC<DateFieldProps> = (props) => {
  return <FunctionField render={(record: any) => {

    return moment(record[props.source!] * 1000).format('MMMM Do YYYY');
  }} />;
}

export default UnixTimestampDateField;