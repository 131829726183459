import React, { useEffect, useState } from 'react';
import { Layout, Sidebar, useGetIdentity } from 'react-admin';
import { useUser } from '../../model/User';
import AppBar from './MyAppBar';
import Menu from './Menu';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const MyLayout = (props: any) => {
  const { height, width } = useWindowDimensions();
  return (<Layout
    {...props}
    style={{ minHeight: height - 100 }}
    appBar={AppBar}
    menu={Menu}
  />);
}

export default MyLayout;
