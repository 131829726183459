import React, { useContext, useEffect, useState } from "react";
import { AutocompleteArrayInput, BooleanInput, Create, FileField, FileFieldProps, FileInput, SimpleForm, TextInput, FormDataConsumer } from "react-admin";

import { UploadStatus, UploadStatusContext } from "../../App";
import CustomFileField from "../CustomFileField/CustomFileField";



export const DocumentCreate = (props: any) => {

  const CKEditorInput = React.lazy(() => import('../CKEditorInput'));

  let context = useContext(UploadStatusContext);
  useEffect(() => {
    context.setUploadStatuses({});
  },[])
  return (
    <Create {...props} redirect="list" mutationMode="pessimistic">
      <SimpleForm>
      <BooleanInput source="from_document" defaultValue={true} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
          formData.from_document?
          <FileInput  multiple={true} source="files" label="Related files" accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
            <CustomFileField source="src" title="title" label="test" uploadStatuses={context.uploadStatuses} />  
          </FileInput>
          :
          <>
          <TextInput source="title" fullWidth />
          <TextInput source="article" fullWidth />
          <CKEditorInput source="content" input={""}/>
          </>}
        </FormDataConsumer>
      </SimpleForm>
    </Create>);
};

export default DocumentCreate