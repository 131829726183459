import * as React from 'react';
import { AppBar, MenuItemLink, UserMenu } from 'react-admin';
import { FC } from 'react';
import { Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const style = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    }
}


const MyUserMenu: FC<any> = props => (
    <UserMenu {...props}>
      <MenuItemLink
            to="/users"
            primaryText="Users"
            leftIcon={<SettingsIcon />} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}    />
    </UserMenu>
  );

const MyAppBar = (props : any) => {
    return (
        <AppBar {...props} color='primary' elevation={0} userMenu={<MyUserMenu/>}>
            <Typography
                variant="h6"
                color="inherit"
                sx={style.title}
                id="react-admin-title"
            />
            <span style={style.spacer} />
        </AppBar>
    );
};

export default MyAppBar;