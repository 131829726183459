import moment from "moment";
import React, { FC } from "react";
import { ArrayField, ChipField, Datagrid, DateFieldProps, FunctionField, List, ResourceComponentInjectedProps, RowClickFunction, SingleFieldList, TextField, TextFieldProps } from "react-admin";
import UnixTimestampDateField from '../UnixTimestampDateField';
import { useUser } from "../../model/User";


export const UsersList: FC<ResourceComponentInjectedProps> = (props) => {
  const {user, loading} = useUser();
  if (!user) {
    return null;
  }
  return (
    <List {...props} hasCreate={user.canCreate('users')}>
      <>
        <Datagrid>
          <TextField source="email" sortable={false}/>
          <UnixTimestampDateField source="created_at" sortable={false} />

      
        </Datagrid>
      </>
    </List>
  )
}