import React, { useEffect, useState } from "react";
import { SimpleShowLayout, RichTextField, Show, TextField, DateField, Labeled, ReferenceOneField } from "react-admin";
import {UnixDateField} from "../UnixTimestampDateField";

const DocumentTitle = ({ record }: any) => {
  return <span>Document {record ? `"${record.title}"` : ''}</span>;
};

export const DocumentShow = (props: any) => {
  return (<Show {...props} title={<DocumentTitle />}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="article" />
      <ReferenceOneField reference="courts" source="court_id" target="id">
        <TextField source="content" emptyText=""/>
      </ReferenceOneField>
      <Labeled label={"verdict_date"}>
        <UnixDateField source="verdict_date"/>
      </Labeled>
      <RichTextField source="content" />
    </SimpleShowLayout>
  </Show>);
};
