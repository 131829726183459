import { Button, Create, ShowView, SimpleForm, TextField, TextInput, Toolbar, useDataProvider } from "react-admin";
import { useUser } from "../../model/User";
import { FC, SetStateAction, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { List } from "lodash";
import { Box, Grid } from "@mui/material";

const styles = {
    rightArrow: {
        backgroundColor: "#0078fe",
        //backgroundColor:"red",
        width: 20,
        height: 25,
        bottom: 0,
        borderBottomLeftRadius: 25,
        right: -10
    },

    rightArrowOverlap: {
        backgroundColor: "#eeeeee",
        //backgroundColor:"green",
        width: 20,
        height: 35,
        bottom: -6,
        borderBottomLeftRadius: 18,
        right: -20

    }
};

const ChatBubble = ({ content, actor}: any) => {
    return (<Box style={{
        backgroundColor: "#3f4a6e",
        padding: 10,
        borderRadius: 25,
        marginTop: 25,
        maxWidth: "75%",
    }}>

        <p style={{ fontSize: 12, color: "#fff", }} >{actor}:</p>
        <p style={{ fontSize: 16, color: "#fff"}} >{content}</p>

    </Box>)
}

const ChatForm = (props: any) => {
    const {getValues, setValue} = useFormContext();
    const [messages, setMessages]= useState<{role: string; content: any; }[]>([]);
    const [isDisabled, setIsDisabled] = useState(false)
    const dataProvider = useDataProvider();
    const { user, loading } = useUser();

    const onsubmit = () => {
        setIsDisabled(true)
        const new_messages = [{"role": "user", "content": getValues("user_message")}, {"role": "assistant", "content": "..."}]
        setMessages([
            ...messages,
            ...new_messages
        ])

        // @ts-ignore
        dataProvider.sendChatMessage({ "history": messages, "message": {"role": "user", "content": getValues("user_message")}})
            .then((json: any) => {
                setMessages(json["data"])
            })
            .finally(() => { 
                setIsDisabled(false); setValue("user_message", "");
            });

    }

    const onclear = () => {
        setMessages([])
    }

    const undo = () => {
        if (messages.length){
            messages.pop()

            setMessages([...messages])
        }
        
    }

    const retry = () => {
        if (messages && messages.length > 0){
            let last_message = messages.pop()
            if (last_message){
                setMessages([...messages])
                setValue("user_message", last_message['content'])
                onsubmit();
            }
        }
        
    }

    return (
        <Grid container justifyContent="center" alignContent="center" alignItems="center" xs={12} direction={"column"}>
            <Grid container xs={6} style={{ borderStyle: "solid", borderWidth: "0.5px", borderColor: "silver", borderRadius: "25px",  height: "100%" }}>
                <Grid xs={12} style={{ borderBottom: "0.5px solid silver", backgroundColor: "#3f4a6e", padding: "20px" , borderRadius: "25px 25px 0px 0px",}}>
                    <h4 style={{ color: "#fff", margin: "0px"}}>Legal assistant</h4>
                </Grid>
                <Grid container style={{ height: "800px", overflow: "scroll", borderBottom: "0.5px solid silver", borderTop: "0.5px solid silver"}}>
                    <Grid container direction="column-reverse" style={{ padding: "5px" }}>
                        {Array.from(messages).reverse().map((actor_messages) => {
                            return (<Grid>
                                <Grid container justifyContent={actor_messages.role === "user" ? "flex-end" : "flex-start"}>
                                    <ChatBubble content={actor_messages.content} actor={actor_messages.role === "user" ? "You" : "Legal assistant"}/>
                                </Grid>
                            </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="stretch" alignContent="space-between" style={{ paddingTop: "10px"}}>
                    <Grid xs={10}>
                        <TextInput source="user_message" placeholder="type a message..." fullWidth disabled={isDisabled || user == undefined || !user.canCreate("legal assistant")}
                            onKeyUp={event => {
                                if (event.key === 'Enter') {
                                    event.stopPropagation();
                                    onsubmit();
                                }
                            }}/>
                    </Grid>
                    <Grid xs={1}>
                        <Button label={"submit"} onClick={onsubmit} fullWidth disabled={isDisabled  || user == undefined || !user.canCreate("legal assistant")}
                            style={{ height: "48px", marginTop: "8px", borderBottomStyle: "solid", borderColor: "rgba(0, 0, 0, 0.42)", borderWidth: "0.5px", borderRadius: "0px", backgroundColor: "rgba(0, 0, 0, 0.04)" }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={6} container justifyContent="space-around" alignContent="stretch" style={{marginTop: "10px"}}>
                <Grid xs={4}>
                    <Button label="retry" fullWidth style={{height: "40px"}} onClick={retry} disabled={isDisabled  || user == undefined || !user.canCreate("legal assistant")}/>
                </Grid>
                <Grid xs={4}>
                    <Button label="undo" fullWidth style={{height: "40px"}} onClick={undo} disabled={isDisabled || user == undefined || !user.canCreate("legal assistant")}/>
                </Grid>
                <Grid xs={4}>
                    <Button label="clear" fullWidth style={{height: "40px"}} onClick={onclear} disabled={isDisabled || user == undefined || !user.canCreate("legal assistant")}/>
                </Grid>

            </Grid>

        </Grid>

    )

}


export const LegalChat = (props: any) => {
    const { user, loading } = useUser();

    if (!user || user == undefined || !user.canView("legal assistant")) {
        return null;
    }


    return (
        <SimpleForm toolbar={false}>
            <ChatForm />
        </SimpleForm>



    )
}