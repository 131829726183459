import moment from "moment";
import React, { FC, useCallback } from "react";
import { useRecordContext, ArrayField, Button, ChipField, CreateButton, Datagrid, DateFieldProps, DeleteWithConfirmButton, FilterLiveSearch, FunctionField, List, ReferenceInput, RowClickFunction, SearchInput, SelectInput, SingleFieldList, TextField, TextFieldProps, Toolbar } from "react-admin";
import UnixTimestampDateField from '../UnixTimestampDateField';
import { useUser } from "../../model/User";
import { createAuthenticatedClient, getApiBaseUrl } from "../../data/documentsDataProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { CloudDownload, PictureAsPdf } from "@mui/icons-material";
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import DraftsIcon from '@mui/icons-material/Drafts';
import PendingIcon from '@mui/icons-material/Pending';
import ReplayIcon from '@mui/icons-material/Replay';

const StateField: FC<TextFieldProps> = (props) => {
  const record = useRecordContext();
  switch (record?.state) {
    case 'published': return <ChipField icon={<SpellcheckIcon />} source="state" color="primary" />;
    case 'draft': return <ChipField icon={<DraftsIcon />} source="state" color="secondary" />;
    case 'pending': return <ChipField icon={<PendingIcon />} source="state" color="default" />;

    default: return <ChipField source="state" color="default" />;
  }
}

const documentRowClick = (user: any): RowClickFunction => {
  return (id, basePath, record) => record.state === 'pending' ? 'expand' : user.canCreate('documents') ? 'edit' : 'show';
}

const DocumentListToolbar = (props: any) => (
  <Toolbar {...props} >
    <CreateButton />
  </Toolbar>
);

const ExportButtonField = (props: any) => {
  const {
    getAccessTokenSilently
  } = useAuth0();

  const record = useRecordContext();

  const handleExportPDF = useCallback(
    (event: any) => {
      (async() => {
        let token = await getAccessTokenSilently();
        const result = await fetch(`${getApiBaseUrl()}/documents/${record?.id}/export/pdf`, {
          headers: {'Authorization': 'Bearer ' + token}
        })
        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob);
        window.open(href);
      })();
      event.stopPropagation();
    }, []);

  const handleExportDocx = useCallback(
      (event: any) => {
        (async() => {
          let token = await getAccessTokenSilently();
          const result = await fetch(`${getApiBaseUrl()}/documents/${record?.id}/export/docx`, {
            headers: {'Authorization': 'Bearer ' + token}
          })
          const blob = await result.blob()
          const href = window.URL.createObjectURL(blob);
          window.open(href);
        })();
        event.stopPropagation();
      }, []);

  const handleReprocess = useCallback(
    (event: any) => {
      (async() => {
        let token = await getAccessTokenSilently();
        const result = await fetch(`${getApiBaseUrl()}/documents/${record?.id}/redo`, {
          headers: {'Authorization': 'Bearer ' + token},
          method: "POST",
        })
        await result.blob()
      })();
      event.stopPropagation();
    }, []);

  if (record?.state === 'published' && props.user.canCreate('documents')) {
    return (<><Button label="PDF" color="primary" onClick={handleExportPDF}>
      <PictureAsPdf />
    </Button><Button label="Word" color="primary" onClick={handleExportDocx}>
      <CloudDownload />
    </Button></>);
  }
  if (record?.state === 'draft' && props.user.canCreate('user')) {
    return (<><Button label="Reprocess" color="primary" onClick={handleReprocess}>
      <ReplayIcon />
    </Button></>);
  }
  return <div></div>;
}


const DeleteButtonField: FC<TextFieldProps> = (props) => {
  if (!props.hidden) {
    return <DeleteWithConfirmButton />;
  }
  return <div></div>;
}

const DocumentFilter = (user: any) => user.permissions.includes('any:admin')?[
  <SearchInput source='key' alwaysOn/>,
  <ReferenceInput source="organisation" reference="organisations" alwaysOn>
    <SelectInput />
  </ReferenceInput>,
  <SelectInput source="state" choices={[
    {id: "published", name: "published"},
    {id: "draft", name: "draft"},
    {id: "pending", name: "pending"}
  ]} alwaysOn/>,
  <ReferenceInput source="court_id" reference="courts" alwaysOn>
    <SelectInput optionText="content"/>
  </ReferenceInput>,
]:[];

export const DocumentsList = (props: any) => {
  const { user, loading } = useUser();
  if (!user) {
    return null;
  }
  return (
    <List {...props} hasCreate={user.canCreate('documents')} filters={DocumentFilter(user)} sort={{ field: 'created', order: 'DESC' }}>
      <>
        <Datagrid rowClick={documentRowClick(user)} bulkActionButtons={false}>
          <TextField source="title" />
          <TextField source="article" sortable={false}/>
          <StateField source="state" />
          <ArrayField source="tags" sortable={false}>
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <UnixTimestampDateField source="created" />
          <ExportButtonField source="id" label="Export" user={user} sortable={false}/>
          <DeleteButtonField hidden={!user.canDestroy('documents')} />
        </Datagrid>
      </>
    </List>
  )
}
