import React from "react";
import { AutocompleteArrayInput, Create, FileField, FileInput, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput } from "react-admin";

export const UserCreate = (props: any) => (
    <Create {...props} redirect="list" mutationMode="pessimistic">
      <SimpleForm>
        <TextInput source="email" fullWidth={true}/>
        <ReferenceArrayInput  source="organisation_id" reference="organisations">
          <SelectInput  optionText="name" />
        </ReferenceArrayInput >
        <ReferenceArrayInput  source="roles" reference="roles">
          <SelectArrayInput  optionText="name" />
        </ReferenceArrayInput >
      </SimpleForm>
    </Create>
  );