// In theme.js
import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      // Your theme goes here
      // Write the following code to have an orange app bar. We'll explain it later in this article.
      primary: {
        light: '#6c759c',
        main: '#3f4a6e',
        dark: '#142343'
      },
      background: {
        default: "#F2F4F8",
      },
    }
  })
);